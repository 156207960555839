import {
  Datagrid,
  Edit,
  Show,
  List,
  SimpleForm,
  ReferenceField,
  FunctionField,
  DateField,
  TextInput,
  SelectField,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  DateInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  ChipField,
  FormDataConsumer,
  TopToolbar,
  TextField,
  EditButton,
  required,
  Toolbar,
  SaveButton,
  useListController,
} from "react-admin";
import { Typography, Grid } from "@mui/material";
import DentalMap from "../components/dentalMap";
import type { Order } from "../types";
import {
  DELIVERY_STATUS_CHOICES_WITH_CANCEL,
  DENTAL_TARGET_CHOICES,
  DENTAL_TARGET_CHOICES_REVERSE,
  FINISHING_TYPE_CHOICES,
  METHOD_TYPE_MAP,
  METHOD_TYPE_MAP_OLD,
  NEED_PRE_CONFIRMATION_CHOICES,
  PRODUCT_CHOICES_WITH_OLD,
  SEX_CHOICES,
  canConnect,
  deliveryStatusMap,
  makeConnectionList,
  makeDentalTexts,
  makeLowerIncludeNeighborSources,
  makeUpperIncludeNeighborSources,
  replaceToConnectionText,
} from "../functions/OrdersFunction";
import { DelayedFileField } from "../components/DelayedFileField";

const DENTAL_TEXTS = makeDentalTexts();

const orderFilters = (filterValues: any) => {
  const filterList = [
    <TextInput source="clinic_name" label="発注医院名" alwaysOn />,

    <ReferenceInput
      source="doctor_id"
      reference="all_doctors"
      allowEmpty
      alwaysOn
    >
      <SelectInput
        optionText={(record) => `${record.last_name} ${record.first_name}`}
        label="医師名"
      />
    </ReferenceInput>,
    <TextInput source="last_kana" label="患者セイ" alwaysOn />,
    <TextInput source="first_kana" label="患者メイ" alwaysOn />,
    <DateInput source="order_start_date" label="開始日" alwaysOn />,
    <DateInput source="order_end_date" label="終了日" alwaysOn />,
    <SelectInput
      source="delivery_status"
      alwaysOn
      label="納品状態"
      choices={DELIVERY_STATUS_CHOICES_WITH_CANCEL}
      optionText="label"
      optionValue="_id"
    />,
  ];
  if (filterValues.delivery_status === undefined) {
    filterList.push(
      <BooleanInput
        label="キャンセルを含む"
        source="with_cancel"
        alwaysOn
        sx={{
          "& .MuiFormControlLabel-label": {
            paddingTop: "14px",
            paddingBottom: "14px",
          },
        }}
      />
    );
  }
  return filterList;
};

export const OrderedList = (props: any) => {
  const listContext = useListController();
  return (
    <List
      {...props}
      filters={orderFilters(listContext.filterValues)}
      title="Amidex　発注システム　受注履歴"
      style={{ paddingTop: "40px" }}
      hasCreate={false}
      exporter={false}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <DateField source="created" label="発注日" />
        <TextField source="clinic_name" label="発注医院" />
        <ReferenceField
          source="doctor_id"
          reference="all_doctors"
          link={false}
          label="医師名"
        >
          <FunctionField
            render={(record: any) => `${record.last_name} ${record.first_name}`}
          />
        </ReferenceField>
        <FunctionField<Order>
          source="last_kana"
          render={(record) => `${record.last_kana} ${record.first_kana}`}
          label="患者氏名（カナ）"
        />
        <SelectField source="sex" choices={SEX_CHOICES} label="性別" />
        <DateField source="birthday" label="生年月日" />
        <FunctionField
          label="納品状態"
          source="delivery_status"
          render={(record: any) => (
            <ChipField
              color={deliveryStatusMap.get(record.delivery_status)?.color}
              source="delivery_status"
              record={{
                delivery_status: deliveryStatusMap.get(record.delivery_status)
                  ?.name,
              }}
            />
          )}
        />
        <EditButton label="編集" />
      </Datagrid>
    </List>
  );
};

export const OrderedEdit = (props: any) => (
  <Edit {...props} title="受注履歴">
    <SimpleForm
      mode="onBlur"
      reValidateMode="onBlur"
      toolbar={<OrderedToolbar />}
    >
      <OrderedEditBase />
    </SimpleForm>
  </Edit>
);

export const OrderedShow = (props: any) => (
  <Show {...props}>
    <SimpleForm mode="onBlur" toolbar={<OrderedToolbar />}>
      <OrderedEditBase toolbar={TopToolbar} isShowMode deliveryStatusDisabled />
    </SimpleForm>
  </Show>
);

export const OrderedToolbar = (props: any) => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const OrderedEditBase = ({
  isShowMode = true,
  deliveryStatusDisabled = false,
  ...props
}) => {
  const record = useRecordContext();

  return (
    <>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        基本情報
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2} mt={2}>
          <Typography variant="subtitle1">医師名</Typography>
        </Grid>
        <Grid item xs={10}>
          <ReferenceInput source="doctor_id" reference="all_doctors">
            <AutocompleteInput
              label={false}
              source="id"
              optionText={(record) =>
                `${record.last_name} ${record.first_name}`
              }
              sx={{ width: "300px" }}
              validate={required("必須です")}
              emptyText="選択してください"
              disabled={isShowMode}
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2} mt={2}>
          <Typography variant="subtitle1">患者名（カナ）</Typography>
        </Grid>
        <Grid item xs="auto">
          <TextInput source="last_kana" label={false} disabled={isShowMode} />
        </Grid>
        <Grid item xs="auto">
          <TextInput source="first_kana" label={false} disabled={isShowMode} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">性別</Typography>
        </Grid>
        <Grid item xs={10}>
          <SelectInput
            source="sex"
            label={false}
            choices={[
              { id: 1, name: "男性" },
              { id: 2, name: "女性" },
              { id: 3, name: "未回答" },
            ]}
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">生年月日</Typography>
        </Grid>
        <Grid item xs={10}>
          <DateInput source="birthday" label={false} disabled={isShowMode} />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        制作する歯にチェックを入れてください。
      </Typography>
      <Grid container ml="10px" sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2.5}>
          <DentalMap
            src="/dental_image.png"
            mapName="ImageMap"
            isShowMode={isShowMode}
          />
        </Grid>
        <Grid item xs mt="40px">
          <Grid container>
            <Grid
              item
              xs="auto"
              style={{
                textAlign: "left",
                borderBottom: "2px solid",
                borderRight: "2px solid",
                paddingRight: "16px",
                paddingBottom: "8px",
              }}
            >
              <p style={{ textAlign: "left", marginBottom: "0" }}>上顎(右)</p>
              <CheckboxGroupInput
                source="quadrant_1"
                label=""
                labelPlacement="bottom"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES_REVERSE}
                disabled={isShowMode}
              />
            </Grid>
            <Grid
              item
              xs="auto"
              style={{
                borderBottom: "2px solid",
                paddingLeft: "16px",
                paddingBottom: "8px",
              }}
            >
              <p style={{ textAlign: "right", marginBottom: "0" }}>上顎(左)</p>

              <CheckboxGroupInput
                source="quadrant_2"
                label=""
                labelPlacement="bottom"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES}
                disabled={isShowMode}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs="auto"
              style={{
                borderRight: "2px solid",
                paddingRight: "16px",
                paddingTop: "16px",
              }}
            >
              <CheckboxGroupInput
                source="quadrant_3"
                label=""
                labelPlacement="top"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES_REVERSE}
                disabled={isShowMode}
              />
              <p style={{ textAlign: "left", marginTop: "-16px" }}>下顎(右)</p>
            </Grid>
            <Grid
              item
              xs="auto"
              style={{ paddingLeft: "16px", paddingTop: "16px" }}
            >
              <CheckboxGroupInput
                source="quadrant_4"
                label=""
                labelPlacement="top"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES}
                disabled={isShowMode}
              />
              <p style={{ textAlign: "right", marginTop: "-16px" }}>下顎(左)</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData, scopedFormData, ...rest }) => {
          if (
            !formData["quadrant_1"] &&
            !formData["quadrant_2"] &&
            !formData["quadrant_3"] &&
            !formData["quadrant_4"]
          )
            return;

          // fromData["quadrant_X"]の中から下記の配列をそれぞれを取り出す
          //   xxxxCheckedSources:         画面でチェックされている歯(product_type_X_X形式)
          //   xxxxIncluedNeighborSources: 画面でチェックされている歯+隣り合う歯(product_type_X_X形式)
          const [upperCheckedSources, upperIncluedNeighborSources] =
            makeUpperIncludeNeighborSources(formData);
          const [lowerCheckedSources, lowerIncluedNeighborSources] =
            makeLowerIncludeNeighborSources(formData);

          // TODO: 本当は上の歯、下の歯、間開く場合を配列で分けて大元からループすると ==== の線を入れられる
          // そうすると、canConnectも先頭かどうかで判定できるので簡単になるはず
          const components = [
            ...upperIncluedNeighborSources,
            ...lowerIncluedNeighborSources,
          ].map((dental_target: string) => {
            return upperCheckedSources.includes(dental_target) ||
              lowerCheckedSources.includes(dental_target) ? (
              <Grid container spacing={2} key={dental_target}>
                <Grid item xs={3.5}>
                  <Typography variant="subtitle1" mt={2}>
                    {DENTAL_TEXTS.get(dental_target)}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <SelectInput
                    source={dental_target}
                    label={false}
                    sx={{ width: "200px" }}
                    isRequired={true}
                    choices={PRODUCT_CHOICES_WITH_OLD}
                    disabled={isShowMode}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} key={dental_target}>
                <Grid item xs={3.5} mt={2}>
                  <Typography variant="subtitle1">
                    {DENTAL_TEXTS.get(dental_target)}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <SelectInput
                    key={dental_target}
                    source={dental_target}
                    label={false}
                    sx={{ width: "200px" }}
                    isRequired={true}
                    defaultValue={0}
                    // formatを入れて強制的に0固定にする
                    format={(value) => 0}
                    choices={[{ id: 0, name: "制作無し" }]}
                    disabled={isShowMode}
                  />
                </Grid>
              </Grid>
            );
          });

          // 連結のコンポーネントは歯の種類のコンポーネントから先頭を切り取ったものとする
          // ※隣り合う歯のうち、後ろを基準に、前の歯に対して連結と考える
          const connectionList = makeConnectionList(
            upperIncluedNeighborSources,
            lowerIncluedNeighborSources
          );
          const connectionComponents = connectionList.map(
            (dental_target: string) => {
              return canConnect(
                dental_target,
                upperIncluedNeighborSources,
                lowerIncluedNeighborSources
              ) ? (
                <BooleanInput
                  key={dental_target}
                  sx={{ pt: "23px" }}
                  source={replaceToConnectionText(dental_target)}
                  label={"連結する"}
                  disabled={isShowMode}
                />
              ) : (
                <Grid item xs sx={{ height: "85px" }} />
              );
            }
          );
          return (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: "20px" }}
              >
                「制作する歯の種類」をプルダウンから選択してください。
              </Typography>
              <Grid container ml={10}>
                <Grid item xs={4} children={components}></Grid>
                <Grid
                  item
                  xs
                  sx={{ pt: "30px" }}
                  children={connectionComponents}
                ></Grid>
              </Grid>
            </>
          );
        }}
      </FormDataConsumer>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        ※制作する歯に関する補足事項（任意）
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={6}>
          <TextInput
            multiline
            label={false}
            source="supplement_info"
            fullWidth
            rows={4}
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        仕上げ方針
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          <RadioButtonGroupInput
            source="finishing_type"
            label={false}
            row={false}
            sx={{ height: "200px" }}
            choices={FINISHING_TYPE_CHOICES}
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        インデックスのご希望
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          {record && record.method_type &&
            <Typography
              color={"rgba(0, 0, 0, 0.38)"}
            >
              {METHOD_TYPE_MAP.get(record.method_type) || METHOD_TYPE_MAP_OLD.get(record.method_type)}
            </Typography>
          }
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        添付zipファイル
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={8}>
          <FunctionField
            label={false}
            render={(record: any) => (
              <DelayedFileField {...record} resource="admin_attachments" />
            )}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        事前の修復形態の確認
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          <RadioButtonGroupInput
            source="need_pre_confirmation"
            row={false}
            label={false}
            validate={required("必須です")}
            choices={NEED_PRE_CONFIRMATION_CHOICES}
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      <SelectInput
        disabled={deliveryStatusDisabled}
        source="delivery_status"
        label="納品状態"
        choices={DELIVERY_STATUS_CHOICES_WITH_CANCEL}
        optionText="label"
        optionValue="_id"
      />
    </>
  );
};
