import { withLifecycleCallbacks, DataProvider, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { basePath } from "./env";
import {
  IS_CONNECTED,
  PRODUCT_MAP,
  isConnected,
  makeDentalTexts,
  makeLowerIncludeNeighborSources,
  makeUpperIncludeNeighborSources,
  replaceToConnectionText,
  replaceToNumber,
  replaceToQuadrant,
} from "../functions/OrdersFunction";

const httpClient = (url: string, options: any = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${localStorage.getItem("token")}`,
  };
  if (url.includes("/users/") && options.method === "PUT") {
    options.method = "PATCH";
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = withLifecycleCallbacks(
  simpleRestProvider(`${basePath}/api/v1`, httpClient),
  [
    {
      /**
       * For orders create and update only, convert uploaded attachments to base 64 and attach them to
       * the `attachments` sent property.
       */
      resource: "orders",
      beforeSave: async (params: any, dataProvider: DataProvider) => {
        const DENTAL_TEXTS = makeDentalTexts();

        let attachments: any = [];
        if (!!params.attachments) {
          // Freshly dropped pictures are File objects and must be converted to base64 strings
          const newAttachments = [params.attachments].filter(
            (p: any) => p.rawFile instanceof File
          );
          const formerAttachments = [params.attachments].filter(
            (p: any) => !(p.rawFile instanceof File)
          );

          const base64Attachments = await Promise.all(
            newAttachments.map(convertFileToBase64)
          );

          attachments = [
            ...base64Attachments.map((dataUrl, index) => ({
              number: index + 1,
              src: dataUrl
                .replace("data:image/png;base64,", "")
                .replace("data:image/jpeg;base64,", "")
                .replace("data:image/jpg;base64,", "")
                .replace("data:image/gif;base64,", "")
                .replace("data:application/zip;base64,", "")
                .replace("data:application/x-zip-compressed;base64,", ""),
              title: newAttachments[index].title,
              size: newAttachments[index].rawFile.size,
            })),
            ...formerAttachments,
          ];
        }

        let dental_targets: any = [];
        let connections: any = [];
        let dental_target_text: string = "";

        const [upperCheckedSources, upperIncluedNeighborSources] =
          makeUpperIncludeNeighborSources(params);
        const [lowerCheckedSources, lowerIncluedNeighborSources] =
          makeLowerIncludeNeighborSources(params);

        [...upperIncluedNeighborSources, ...lowerIncluedNeighborSources]
          .filter((dental_target) => {
            return params[dental_target] !== undefined;
          })
          .map((dental_target) => {
            if (isConnected(dental_target, params)) {
              dental_target_text = dental_target_text + "\n" + "　　↑↓連結";
              connections.push({
                id: replaceToConnectionText(dental_target),
                quadrant: replaceToQuadrant(dental_target),
                number: replaceToNumber(dental_target),
                connection_type: IS_CONNECTED,
              });
            }

            // 歯をチェックして歯の種類を選択した後に歯のチェックを外すと、formDataには歯の種類の値が残存しているため、
            // 歯の種類の値が0かどうかだけでなく、必ず最終時点でチェックが入っている歯なのかチェックする必要がある
            const isCheckedDental =
              upperCheckedSources.includes(dental_target) ||
              lowerCheckedSources.includes(dental_target);

            if (isCheckedDental && params[dental_target] !== 0) {
              dental_target_text =
                dental_target_text +
                "\n" +
                DENTAL_TEXTS.get(dental_target) +
                " " +
                PRODUCT_MAP.get(params[dental_target]);
              dental_targets.push({
                id: dental_target,
                quadrant: replaceToQuadrant(dental_target),
                number: replaceToNumber(dental_target),
                product_type: params[dental_target],
              });
            } else {
              dental_target_text =
                dental_target_text + "\n" + DENTAL_TEXTS.get(dental_target);
            }
          });

        //先頭の改行を削除
        dental_target_text = dental_target_text.slice(1);

        return {
          ...params,
          attachments,
          dental_targets,
          connections,
          dental_target_text,
        };
      },
    },
  ]
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store attachments in production, but it's
 * enough to illustrate the idea of dataprovider decoration.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

export default dataProvider;
